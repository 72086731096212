import React, { useContext } from "react";
import classNames from "classnames";
import { useDispatch } from "react-redux";

import { setMoveDownPopups } from "../../redux/slices/applicationSlice";
import { useAppSelector } from "../../redux/hooks";

import { MapContext } from "../Map/Map";

import { Button } from "..";

import styles from "./MoveDownView.module.scss";

interface Props {
  className?: string;
  children: React.ReactNode;
}

export default function MoveDownView({ className, children }: Props) {
  const dispatch = useDispatch();

  const { controlSize, controlTheme, featureHorizonHeight } =
    useContext(MapContext);

  const { moveDownPopups } = useAppSelector((state) => state.application);

  const handleOnClick = () => {
    moveDownPopups
      ? dispatch(setMoveDownPopups(false))
      : dispatch(setMoveDownPopups(true));
  };

  return (
    <div
      className={classNames(className, styles.container, {
        [styles.accessibleHeight]: moveDownPopups,
      })}
      {...(featureHorizonHeight && !moveDownPopups
        ? {
            style: {
              bottom: featureHorizonHeight,
            },
          }
        : {})}
    >
      {children}
      <Button
        leftIcon={moveDownPopups ? "UpShortArrowIcon" : "DownShortArrowIcon"}
        dataQA={moveDownPopups ? "move-up-button" : "move-down-button"}
        label={moveDownPopups ? "Move up" : "Move down"}
        onClick={handleOnClick}
        size={controlSize}
        rounded
        color={controlTheme === "light" ? "black" : "white"}
        className={classNames(styles.button, styles[controlSize])}
      />
    </div>
  );
}
