import classNames from "classnames";

import { UISize, UITheme } from "../../../types";

import { useAppSelector } from "../../../../redux/hooks";

import MoveDownView from "../../../MoveDownView/MoveDownView";

import styles from "./Modal.module.scss";

interface Props {
  className?: string;
  dataQA: string;
  children: React.ReactNode;
  title: string;
  size?: UISize;
  theme?: UITheme;
}

export default function Modal({
  className,
  dataQA,
  children,
  title,
  size = "small",
  theme = "light",
}: Props) {
  const { moveDownPopups } = useAppSelector((state) => state.application);

  return (
    <MoveDownView
      className={classNames(styles.container, {
        [styles.accessibleHeight]: moveDownPopups,
      })}
    >
      <div
        data-qa={dataQA}
        className={classNames(
          styles.modal,
          styles[size],
          styles[theme],
          className,
        )}
      >
        <p className={styles.title}>{title}</p>
        {children}
      </div>
    </MoveDownView>
  );
}
