import type { SerializedError } from "@reduxjs/toolkit";
import type { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";

interface APIError {
  code: number;
  message: string;
  type: string;
}

export const errorHandler = (
  error: FetchBaseQueryError | SerializedError | undefined
) => {
  if (error && "status" in error) {
    let message = "";

    switch (error.status) {
      case 400:
      case 404:
        message = "Invalid Screen Code";
        break;
      default:
        message = (error.data as APIError)?.message || "An error occurred";
    }

    window.displayErrorScreen(message);
  }
};
