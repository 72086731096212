import classNames from "classnames";
import { useContext } from "react";

import { MapContext } from "../Map/Map";

import { Button, Modal } from "../../components";

import styles from "./ShareToMobileModal.module.scss";

export interface ShareToMobileModalProps {
  qrCode: {
    svg_base64: string;
  };
  onClick: () => void;
}

export default function ShareToMobileModal({
  qrCode,
  onClick,
}: ShareToMobileModalProps) {
  const { controlSize, controlTheme } = useContext(MapContext);

  return (
    <Modal
      dataQA="share-to-mobile-modal"
      title="Share to mobile"
      size={controlSize}
      theme={controlTheme}
      className={classNames(styles[controlSize], styles[controlTheme])}
    >
      <img
        data-qa="qr-code"
        src={`data:image/svg+xml;base64,${qrCode.svg_base64}`}
        alt="QR Code"
        className={styles.qrCode}
      />
      <p className={styles.description}>Scan this with your phone</p>
      <Button
        dataQA="share-done-button"
        label="Done"
        onClick={onClick}
        size={controlSize}
        rounded
        color={controlTheme === "light" ? "black" : "white"}
      />
    </Modal>
  );
}
