import { useContext } from "react";
import classNames from "classnames";
import { Icon } from "@livingmap/core-ui-v2";

import { MapContext } from "../../Map";

import styles from "./Compass.module.scss";

export interface CompassProps {
  bearing: number;
}

const Compass: React.FC<CompassProps> = ({ bearing }) => {
  const { controlSize, controlTheme } = useContext(MapContext);

  return (
    <div
      className={classNames({
        [styles.container]: true,
        [styles[controlSize]]: true,
        [styles[controlTheme]]: true,
      })}
    >
      <div
        style={{
          display: "flex",
          transform: `rotate(${-bearing}deg)`,
        }}
      >
        <Icon
          dataQA="compass-icon"
          type={"CompassIcon"}
          className={styles.svg}
        />
      </div>
    </div>
  );
};

export default Compass;
