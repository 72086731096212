import { useContext, useEffect, useState } from "react";
import classNames from "classnames";

import { Feature, Floors } from "../../../../redux/services/config";

import { MapContext } from "../../Map";

import styles from "./SearchResults.module.scss";
import SearchResult from "../../../SearchResult/SearchResult";

function normalize(string: string) {
  if (!string) return string;

  return string
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "") // replace diacritics with their 'base' letter (i.e. é -> e)
    .replace(/ /g, ""); // remove spaces
}

function regexify(searchTerm: string): RegExp {
  const normalizedSearchTerm = normalize(searchTerm);

  const result = normalizedSearchTerm
    .replace(/.{1}/g, "($&|[^a-zA-Z0-9])") // any character can be substituted for non-alphanumeric characters
    .replace(/.{16}/g, "$&[^a-zA-Z0-9]?"); // allow any non alphanumeric character after each original character
  return new RegExp(result, "gi");
}

interface SearchResultStore {
  [key: string]: Feature[];
}

const MIN_CHARS = 2;

export interface SearchResultsProps {
  dataQA: string;
  features: Feature[];
  searchTerm: string;
  className?: string;
  enableImages: boolean;
  onSearchInputActive: (enabled: boolean) => void;
  onResultClick: (features: Feature[]) => void;
  onScroll?: () => void;
  floors: Floors;
}

const SearchResults: React.FC<SearchResultsProps> = ({
  dataQA,
  features,
  searchTerm,
  onSearchInputActive,
  onResultClick,
  onScroll,
  className,
  enableImages,
  floors,
}) => {
  const { controlTheme, controlSize } = useContext(MapContext);

  // Store the search results in an object where the key is the feature name which holds an array of features associated with the same name, so we can return all of them on result click
  const [resultStore, setResultStore] = useState<SearchResultStore>({});

  const formattedFloors = Object.values(floors).map(({ id, name }) => ({
    id,
    name,
  }));

  useEffect(() => {
    setResultStore({});
    if (searchTerm.length >= MIN_CHARS) {
      for (const feature of features) {
        if (
          !feature.properties.name ||
          !regexify(searchTerm).test(
            normalize(feature.properties.name.toLowerCase()),
          )
        )
          continue;

        setResultStore((prevResultStore) => ({
          ...prevResultStore,
          [feature.properties.name]: prevResultStore[feature.properties.name]
            ? [...prevResultStore[feature.properties.name], feature]
            : [feature],
        }));
      }
    }
  }, [features, onSearchInputActive, searchTerm]);

  useEffect(() => {
    if (searchTerm.length >= MIN_CHARS) {
      onSearchInputActive(Object.keys(resultStore).length > 0);
    } else {
      onSearchInputActive(true);
    }
  }, [onSearchInputActive, resultStore, searchTerm.length]);

  if (Object.keys(resultStore).length === 0 && searchTerm.length < 2)
    return null;

  return (
    <div
      data-qa={dataQA}
      className={classNames(
        styles.container,
        styles[controlTheme],
        styles[controlSize],
        className,
      )}
    >
      <div className={styles.scrollContainer} onScroll={onScroll}>
        <ul className={styles.listContainer}>
          {Object.keys(resultStore).map((resultKey) => {
            const feature = resultStore[resultKey][0];

            const regex = regexify(searchTerm);
            const wrappedString = feature.properties.name
              .replace(regex, "<span>$&</span>")
              .replace(/ /g, "&nbsp;"); // ensures whitespace is still rendered inside of the "inline-block" span. Span needs to be set to "inline-block" so the highlighted background stretches the full line-height of the p tag

            return (
              <SearchResult
                category={feature.properties.popup_subheader}
                className={styles.searchResult}
                floor={
                  formattedFloors.find(
                    ({ id }) => id === feature.properties.floor_id,
                  )?.name
                }
                imageUrl={feature.properties.popup_image_url}
                key={feature.id}
                onClick={() => onResultClick(resultStore[resultKey])}
                openingTimes={feature.properties.opening_times}
                showImage={enableImages}
                title={wrappedString}
                titleSearch={true} // TODO: this will depend on whether it is offline/online mode in the future
                isTemporarilyClosed={feature.properties.is_temporarily_closed}
              />
            );
          })}
        </ul>
      </div>
      {!Object.keys(resultStore).length && searchTerm.length >= MIN_CHARS && (
        <div className={styles.noResultsContainer}>
          <div className={styles.title}>No results</div>
          <div className={styles.paragraph}>
            Check your spelling or try another search.
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchResults;
