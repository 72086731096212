import { useContext, useRef } from "react";
import classNames from "classnames";
import { Icon, IconType } from "@livingmap/core-ui-v2";

import { MapContext } from "../../../../Map";
import styles from "./KeyboardButton.module.scss";

interface BaseProps {
  dataQA?: string;
  className?: string;
  onClick: (...args: any[]) => void;
  verticalAlign?: boolean;
}

interface TextProps extends BaseProps {
  type: "text";
  text: string;
  value?: string;
}

interface IconProps extends BaseProps {
  type: "icon";
  icon: {
    type: IconType;
    width?: number;
    height?: number;
  };
  value: string;
}

type Props = TextProps | IconProps;

const KeyboardButton: React.FC<Props> = ({
  dataQA,
  value,
  onClick,
  className,
  verticalAlign = true,
  ...props
}) => {
  const { controlTheme, controlSize } = useContext(MapContext);

  const timeoutID = useRef<ReturnType<typeof setTimeout> | null>(null);

  // handle active styling being added/removed on click, as the :focus, :hover or :active pseudo classes won't cover the requirements of this styling to apply per click, if the same button gets clicked multiple times.
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (timeoutID.current) {
      clearTimeout(timeoutID.current);
      timeoutID.current = null;
    }

    e.currentTarget.classList.add(styles.activeKey);

    timeoutID.current = setTimeout(() => {
      // currentTarget gets set to null prior to the setTimeout executing, so e.target needs to be used here
      (e.target as HTMLButtonElement).classList.remove(styles.activeKey);
    }, 200);

    onClick(e);
  };

  let buttonName = value;

  if (!buttonName && "text" in props) {
    buttonName = props.text;
  }

  return (
    <button
      data-qa={dataQA}
      name={buttonName}
      className={classNames(
        styles.key,
        styles[controlSize],
        styles[controlTheme],
        className,
        {
          [styles.iconButton]: "icon" in props,
        },
      )}
      onClick={handleClick}
    >
      {props.type === "text" &&
        (verticalAlign ? (
          <div className={styles.verticalAlign}>{props.text}</div>
        ) : (
          props.text
        ))}
      {props.type === "icon" && (
        <Icon dataQA={`${dataQA}-icon`} {...props.icon} />
      )}
    </button>
  );
};

export default KeyboardButton;
