import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ApplicationState {
  moveDownPopups: boolean;
}

const initialState: ApplicationState = {
  moveDownPopups: false,
};

export const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {
    setMoveDownPopups: (state, action: PayloadAction<boolean>) => {
      state.moveDownPopups = action.payload;
    },
  },
});

export const { setMoveDownPopups } = applicationSlice.actions;

export default applicationSlice.reducer;
