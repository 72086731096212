import { Icon } from "@livingmap/core-ui-v2";
import classNames from "classnames";

import { Theme } from "../../../../redux/services/config";
import styles from "./AssetLabel.module.scss";

interface Props {
  dataQA: string;
  theme: Theme;
  name: string;
  floor?: string;
  className?: string;
  onClick: () => void;
}

const AssetLabel: React.FC<Props> = ({
  dataQA,
  theme,
  name,
  floor,
  onClick,
  className,
}) => {
  return (
    <div
      data-qa={dataQA}
      onClick={onClick}
      className={classNames(
        styles.container,
        styles[theme.mode || "light"],
        styles[theme.size || "small"],
        className
      )}
    >
      <div className={styles.textWrapper}>
        <span className={styles.name}>{name}</span>
        {floor && <span className={styles.floor}>{floor}</span>}
      </div>
      <div className={styles.iconWrapper}>
        <Icon
          dataQA="touch-icon"
          type="OneFingerTapIcon"
          className={styles.icon}
        />
      </div>
    </div>
  );
};

export default AssetLabel;
