import { useState, useRef, useCallback, useEffect } from "react";
import { useTransition, animated, config } from "react-spring";
import { Icon } from "@livingmap/core-ui-v2";
import classNames from "classnames";
import type { FitBoundsOptions } from "mapbox-gl";
import type LivingMap from "@livingmap/core-mapping";

import {
  Map,
  Compass,
  Header,
  CentreControl,
  FloorSelector,
  PanControl,
  SearchControl,
  SearchResults,
  Keyboard,
  ZoomControl,
  LocationButton,
  LocationStatus,
} from "../../components";
import { useSession, useImagePreloader } from "../../hooks";
import {
  ConfigurationResponse,
  InteractionEventTypes,
  Feature,
} from "../../redux/services/config";
import { PLUGIN_IDS } from "../../components/Map/plugins/types";
import FloorControl from "../../components/Map/plugins/floor-control";
import PositionPlugin from "../../components/Map/plugins/position-control";
import ClusteredPinPlugin from "../../components/Map/plugins/clustered-pin-control";
import { createLMFeatures, getBoundingBox, throttle } from "../../utils";
import styles from "./BaseWithHeader.module.scss";
import IFrame from "../../components/IFrame/IFrame";
import Button from "../../components/Button/Button";
import ShareToMobileModal from "../../components/ShareToMobileModal/ShareToMobileModal";
import MoveDownView from "../../components/MoveDownView/MoveDownView";
import { useDispatch } from "react-redux";
import { setMoveDownPopups } from "../../redux/slices/applicationSlice";
import { useAppSelector } from "../../redux/hooks";

export interface OnTouchHandlerOptions {
  featureID?: string | number;
  featureName?: string;
}

interface Props {
  data: ConfigurationResponse;
  features?: Feature[];
}

const BaseWithHeader: React.FC<Props> = ({ data, features }) => {
  const dispatch = useDispatch();

  const { moveDownPopups } = useAppSelector((state) => state.application);

  const mapInstance = useRef<LivingMap | null>(null);
  const floorControlInstance = useRef<FloorControl | null>(null);
  const positionControlInstance = useRef<PositionPlugin | null>(null);
  const clusteredPinControlInstance = useRef<ClusteredPinPlugin | null>(null);

  const [mapIsReady, setMapIsReady] = useState(false);
  const [hasInteracted, setHasInteracted] = useState(false);
  const [userLocationStyle, setUserLocationStyle] = useState({
    colour: "#3c78ff",
    borderColour: "#fff",
    displayPulse: true,
  });
  const [searchIsActive, setSearchIsActive] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchInputActive, setSearchInputActive] = useState(true);
  const [zoomLimitReached, setZoomLimitReached] = useState({
    min: false,
    max: false,
  });
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isLocationCentered, setIsLocationCentered] = useState(true);
  const [footerHeight, setFooterHeight] = useState(0);

  const [poiFloorId, setPoiFloorId] = useState<number | null>(null);

  const footerRef = useRef<HTMLDivElement>(null);

  const transitions = useTransition(searchIsActive, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    reverse: searchIsActive,
    config: {
      ...config.molasses,
      duration: 200,
    },
  });

  const {
    components: { map_primary, body, handoff_bar, top_bar, iframe },
    languages,
    stylesheet,
    location,
    server,
    floors,
    project,
    map_key: mapboxApiKey,
    screen_id,
  } = data;

  const { controls: primaryControls, search } = map_primary;
  const { enable_images, pre_load_images } = search;

  const handleSessionTimeout = () => {
    setUserLocationStyle({
      colour: "#3c78ff",
      borderColour: "#fff",
      displayPulse: true,
    });

    mapInstance.current
      ?.getMapboxMap()
      .easeTo({ center: map_primary.center, zoom: map_primary.zoom.init });
    floorControlInstance.current?.setActiveFloor(floors[map_primary.floor]);
    clusteredPinControlInstance.current?.clearFeatureLabels();
    setHasInteracted(false);

    setZoomLimitReached({
      min: map_primary.zoom.init === map_primary.zoom.min,
      max: map_primary.zoom.init === map_primary.zoom.max,
    });

    if (searchIsActive) {
      handleTouchEvent(
        InteractionEventTypes.SEARCH_DIALOG_CLOSE,
        !!map_primary?.interaction?.session_logging,
        false,
        {
          event_data: searchTerm,
        },
      );

      setSearchIsActive(false);
      setSearchTerm("");
    }
  };

  const handleMapRecentre = () => {
    handleOnTouch(InteractionEventTypes.RE_CENTER_MAP_TOUCH);
    setIsLocationCentered(true);
  };

  const { handleTouchEvent, noticeActive, resetTimeouts } = useSession(
    map_primary.interaction.session_timeout,
    map_primary.interaction.session_timeout_notice,
    handleSessionTimeout,
    screen_id,
  );

  const { imagesPreloaded } = useImagePreloader(features, pre_load_images);

  useEffect(() => {
    if (!mapIsReady) return;
    if (!primaryControls.show.search) {
      return window.removeSplashScreen();
    }

    if (imagesPreloaded || !pre_load_images) {
      window.removeSplashScreen();
    }
  }, [
    mapIsReady,
    imagesPreloaded,
    primaryControls.show.search,
    pre_load_images,
  ]);

  const handleMapReady = (map: LivingMap) => {
    mapInstance.current = map;
    floorControlInstance.current = map.getPluginById<FloorControl>(
      PLUGIN_IDS.FLOOR,
    );
    positionControlInstance.current = map.getPluginById<PositionPlugin>(
      PLUGIN_IDS.USER_LOCATION,
    );
    clusteredPinControlInstance.current = map.getPluginById<ClusteredPinPlugin>(
      PLUGIN_IDS.CLUSTERED_PIN,
    );

    setZoomLimitReached({
      min: map_primary.zoom.init === map_primary.zoom.min,
      max: map_primary.zoom.init === map_primary.zoom.max,
    });

    setMapIsReady(true);
  };

  const handleOnTouch = (
    eventType: InteractionEventTypes,
    options?: OnTouchHandlerOptions,
  ) => {
    const extraData: { [key: string]: any } = {};

    if (eventType === InteractionEventTypes.ASSET_DIALOG_CLOSE) {
      return resetTimeouts();
    }

    if (
      eventType === InteractionEventTypes.SEARCH_RESULT_OPEN &&
      options?.featureName
    ) {
      extraData.event_data = options.featureName;
    }

    if (eventType === InteractionEventTypes.SEARCH_DIALOG_CLOSE) {
      extraData.event_data = searchTerm;
    }

    if (
      eventType === InteractionEventTypes.ASSET_DIALOG_OPEN &&
      options?.featureID
    ) {
      extraData.event_data = options.featureID;
    }

    handleTouchEvent(
      eventType,
      !!map_primary?.interaction?.session_logging,
      true,
      extraData,
    );

    if (eventType === InteractionEventTypes.RE_CENTER_MAP_TOUCH) {
      mapInstance.current
        ?.getMapboxMap()
        .easeTo({ center: map_primary.center, zoom: map_primary.zoom.init });
      floorControlInstance.current?.setActiveFloor(floors[map_primary.floor]);

      setUserLocationStyle({
        colour: "#3c78ff",
        borderColour: "#fff",
        displayPulse: true,
      });

      setZoomLimitReached({
        min: map_primary.zoom.init === map_primary.zoom.min,
        max: map_primary.zoom.init === map_primary.zoom.max,
      });

      setHasInteracted(false); // So the re-centre control disappears when the map has been re-centred

      clusteredPinControlInstance.current?.reloadFeatureLabels();
    } else if (
      eventType !== InteractionEventTypes.SEARCH_CONTROL_TOUCH &&
      eventType !== InteractionEventTypes.SEARCH_DIALOG_CLOSE &&
      eventType !== InteractionEventTypes.SESSION_EXTENDER_TOUCH
    ) {
      setHasInteracted(true);
    }

    if (eventType !== InteractionEventTypes.LEVEL_SELECTOR_TOUCH) return;

    if (
      floorControlInstance.current?.getActiveFloor()?.id !==
      floors[map_primary.you_marker.floor].id
    ) {
      setUserLocationStyle({
        colour: "#BDBDBD",
        borderColour: "#757575",
        displayPulse: false,
      });
    } else {
      setUserLocationStyle({
        colour: "#3c78ff",
        borderColour: "#fff",
        displayPulse: true,
      });
    }

    clusteredPinControlInstance.current?.reloadFeatureLabels();
  };

  const handleResultClick = (features: Feature[]) => {
    handleOnTouch(InteractionEventTypes.SEARCH_RESULT_OPEN, {
      featureName: features[0].properties.name,
    });

    const bounds = getBoundingBox(features);
    const options: FitBoundsOptions = {
      bearing: map_primary.bearing,
      padding: 200,
    };

    if (features.length === 1) {
      options.zoom = map_primary.zoom.init; // Stick to the default zoom level if there's only one feature to display
    }

    mapInstance.current?.getMapboxMap().fitBounds(bounds, options);

    const lmFeatures = createLMFeatures(features);

    clusteredPinControlInstance.current?.updateFeatureLabels(lmFeatures, true);

    setZoomLimitReached({
      min: map_primary.zoom.init === map_primary.zoom.min,
      max: map_primary.zoom.init === map_primary.zoom.max,
    });
    setSearchIsActive(false);
    setSearchTerm("");
  };

  const handleSearchControlClick = () => {
    setSearchIsActive(true);
    clusteredPinControlInstance.current?.clearFeatureLabels();
    handleOnTouch(InteractionEventTypes.SEARCH_CONTROL_TOUCH);
  };

  const handleSearchInputActive = (state: boolean) => {
    setSearchInputActive(state);
  };

  const handleKeyboardClose = () => {
    setSearchIsActive(false);
    handleOnTouch(InteractionEventTypes.SEARCH_DIALOG_CLOSE);
  };

  const handleKeyboardOnChange = useCallback(
    (value: string) => {
      resetTimeouts(); // Restart session timeouts
      setSearchTerm(value);
    },
    [resetTimeouts],
  );

  const handleZoomPinch = (zoomLevel: number) => {
    zoomUpdate(zoomLevel);
  };

  const handleZoomClick = (zoomLevel: number) => {
    handleOnTouch(InteractionEventTypes.ZOOM_CONTROL_TOUCH);
    zoomUpdate(zoomLevel);
  };

  const zoomUpdate = (zoomLevel: number) => {
    const minZoom = map_primary.zoom.min;
    const maxZoom = map_primary.zoom.max;

    if (zoomLevel <= minZoom) {
      setZoomLimitReached({
        min: true,
        max: false,
      });

      return;
    }

    if (zoomLevel >= maxZoom) {
      setZoomLimitReached({
        min: false,
        max: true,
      });

      return;
    }

    setZoomLimitReached({
      min: false,
      max: false,
    });
  };

  const handleOnTimeout = () => {
    dispatch(setMoveDownPopups(false));
    setPoiFloorId(null);
  };

  useEffect(() => {
    if (!mapIsReady) return;

    positionControlInstance.current?.updateUserLocationStyle(
      userLocationStyle.colour,
      userLocationStyle.borderColour,
      userLocationStyle.displayPulse,
    );
  }, [
    mapIsReady,
    userLocationStyle.colour,
    userLocationStyle.borderColour,
    userLocationStyle.displayPulse,
  ]);

  useEffect(() => {
    const footer = footerRef?.current;

    if (!footer) return;

    const observer = new ResizeObserver(() => {
      setFooterHeight(footer.clientHeight);
    });

    observer.observe(footer);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div
      className={styles.container}
      style={{ backgroundColor: body.theme.background_color }}
    >
      <Header
        languages={languages}
        lang={top_bar.lang!}
        theme={top_bar.theme}
        icon={top_bar.icon}
        time={server.time}
        timezone={location.timezone}
      />
      {iframe && (
        <IFrame
          dataQA="iframe-container"
          backgroundColor={
            iframe.theme.background_color ? iframe.theme.background_color : ""
          }
          height={iframe.height}
          position={iframe.position}
          url={iframe.url}
        />
      )}

      <div className={styles.primaryMap}>
        <Map
          mapID={screen_id}
          bearing={map_primary.bearing}
          zoom={map_primary.zoom.init}
          maxZoom={map_primary.zoom.max}
          minZoom={map_primary.zoom.min}
          center={map_primary.center}
          extent={map_primary.extent}
          mapStyle={stylesheet}
          floor={map_primary.floor}
          floors={floors}
          accessToken={mapboxApiKey}
          youMarker={map_primary.you_marker}
          controlTheme={primaryControls.theme.mode}
          controlSize={primaryControls.theme.size}
          interactive={!!map_primary.interaction.enabled}
          enableTouchZoom={primaryControls.show.zoom_controls}
          onTouch={handleOnTouch}
          onZoom={handleZoomPinch}
          onMapReady={handleMapReady}
          onMapDrag={() => setIsLocationCentered(false)}
          featureHorizonHeight={primaryControls.theme.feature_horizon_height}
          onFeatureSelect={(feature) =>
            setPoiFloorId(feature ? feature.properties?.poi_floor_id : null)
          }
        >
          {features &&
            transitions(
              (transitionStyles, show) =>
                show && (
                  <MoveDownView
                    className={classNames(styles.searchWrapper, {
                      [styles.accessibleHeight]: moveDownPopups,
                    })}
                  >
                    <animated.div
                      style={transitionStyles}
                      className={styles.animatedWrapper}
                    >
                      <div
                        className={classNames(
                          styles.searchHeightPadder,
                          styles[primaryControls.theme.size],
                        )}
                      >
                        <SearchResults
                          dataQA="search-results"
                          features={features}
                          searchTerm={searchTerm.trim()}
                          onSearchInputActive={handleSearchInputActive}
                          onResultClick={(features) =>
                            handleResultClick(features)
                          }
                          onScroll={throttle(resetTimeouts, 500)}
                          className={classNames(
                            styles.searchResultsContainer,
                            styles[primaryControls.theme.size],
                          )}
                          enableImages={enable_images}
                          floors={floors}
                        />
                      </div>
                      <Keyboard
                        project={project}
                        dataQA="search-keyboard"
                        onClose={handleKeyboardClose}
                        onChange={handleKeyboardOnChange}
                        enableInput={searchInputActive}
                      />
                    </animated.div>
                  </MoveDownView>
                ),
            )}
          {isShareModalOpen && (
            <ShareToMobileModal
              qrCode={handoff_bar.qr_code}
              onClick={() => setIsShareModalOpen(false)}
            />
          )}
          {primaryControls.show.re_center_map && (
            <CentreControl
              isInactive={noticeActive}
              onRecentre={handleMapRecentre}
              onTouch={handleOnTouch}
              hasPanned={hasInteracted}
              noticeTimeout={
                map_primary.interaction.session_timeout -
                map_primary.interaction.session_timeout_notice
              }
              onTimeout={handleOnTimeout}
            />
          )}
          {primaryControls.show.north_marker && (
            <div
              className={classNames(
                styles.primaryCompassContainer,
                styles[primaryControls.theme.size],
              )}
            >
              <Compass bearing={map_primary.bearing} />
            </div>
          )}
          {/* Left map controls */}
          {(primaryControls.show.zoom_controls ||
            primaryControls.show.pan_control) && (
            <div
              className={classNames(
                styles.controlContainer,
                styles.controlLeftContainer,
                styles[primaryControls.theme.size],
              )}
              style={{
                bottom: `${
                  (primaryControls.theme.margin_bottom || 32) + footerHeight
                }px`,
              }}
            >
              {primaryControls.show.zoom_controls && (
                <ZoomControl
                  className={styles.zoomControlContainer}
                  minZoomReached={zoomLimitReached.min}
                  maxZoomReached={zoomLimitReached.max}
                  onZoomClick={handleZoomClick}
                />
              )}
              {primaryControls.show.pan_control && (
                <div
                  className={classNames(
                    styles.controlTopMargin,
                    styles[primaryControls.theme.size],
                  )}
                >
                  <PanControl
                    onTouch={handleOnTouch}
                    hasPanned={hasInteracted}
                    setHasPanned={setHasInteracted}
                  />
                </div>
              )}
            </div>
          )}
          {/* Right map controls */}
          {primaryControls.show.level_selector && (
            <div
              className={classNames(
                styles.controlContainer,
                styles.controlRightContainer,
                styles[primaryControls.theme.size],
              )}
              style={{
                bottom: `${
                  (primaryControls.theme.margin_bottom || 32) + footerHeight
                }px`,
              }}
            >
              {primaryControls.show.level_selector && (
                <FloorSelector
                  dataQA="floor-container"
                  floors={floors}
                  youAreHereFloor={map_primary.you_marker.floor}
                  onTouch={handleOnTouch}
                  poiFloorId={poiFloorId}
                  activeFloor={
                    floorControlInstance.current?.getActiveFloor() ||
                    map_primary.floor
                  }
                />
              )}
              {primaryControls.show.re_center_map && (
                <div
                  className={classNames(
                    styles.controlTopMargin,
                    styles[primaryControls.theme.size],
                  )}
                >
                  <LocationButton
                    dataQA="location-button"
                    onClick={handleMapRecentre}
                    size={primaryControls.theme.size}
                    status={
                      isLocationCentered
                        ? LocationStatus.FOUND
                        : LocationStatus.INACTIVE
                    }
                    theme={primaryControls.theme.mode}
                  />
                </div>
              )}
            </div>
          )}

          <div
            className={classNames(
              styles.footerContainer,
              styles[primaryControls.theme.size],
            )}
            ref={footerRef}
          >
            {features && primaryControls.show.search && (
              <div className={styles.searchContainer}>
                <SearchControl
                  dataQA="search"
                  buttonStyle="smallSquare"
                  className={styles.smallSearchControlContainer}
                  onClick={handleSearchControlClick}
                  project={project}
                />
              </div>
            )}
            <div className={styles.quickSearchTagsContainer} />
            <div className={styles.attributionShareContainer}>
              <div className={styles.content}>
                {handoff_bar && (
                  <Button
                    dataQA="share-to-mobile"
                    onClick={() => setIsShareModalOpen(true)}
                    label="Share to mobile"
                    color="black"
                    rounded
                    leftIcon="ShareDirectionsIcon"
                    outlined
                    size="small"
                    className={styles.shareButton}
                  />
                )}
                <div className={styles.attribution}>
                  <Icon
                    className={styles.logo}
                    dataQA="attribution-logo"
                    type="LmLogomarkIcon"
                  />
                  <span>&copy; Living Map</span>
                  <span>&copy; OpenStreetMap</span>
                </div>
              </div>
            </div>
          </div>
        </Map>
      </div>
    </div>
  );
};

export default BaseWithHeader;
