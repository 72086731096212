import { useEffect, useRef, useState, createContext } from "react";
import LivingMap, { LivingMapOptions } from "@livingmap/core-mapping";
import "mapbox-gl/dist/mapbox-gl.css";
import { Feature } from "geojson";

import { Floors, InteractionEventTypes } from "../../redux/services/config";

import FloorControl, { FloorConfig } from "./plugins/floor-control";
import PositionControl from "./plugins/position-control";
import ClusteredPinPlugin from "./plugins/clustered-pin-control";
import InteractionPlugin from "./plugins/interaction-control";
import { PLUGIN_IDS } from "./plugins/types/index";

import { UISize, UITheme } from "../types";

import Spinner from "../Spinner/Spinner";

import styles from "./Map.module.css";

interface Props {
  mapID: string;
  zoom: number;
  maxZoom: number;
  minZoom: number;
  center: [number, number];
  extent: [number, number, number, number];
  bearing: number;
  mapStyle: string;
  accessToken: string;
  floor: string;
  floors: Floors;
  youMarker: {
    bearing: number;
    latitude: number;
    longitude: number;
    floor: string;
  };
  controlTheme?: UITheme;
  controlSize?: UISize;
  interactive?: boolean;
  enableTouchZoom?: boolean;
  onFeatureSelect?: (feature: Feature | null) => void;
  onMapReady?: (map: LivingMap) => void;
  onTouch?: (eventType: InteractionEventTypes) => void;
  onZoom?: (zoomLevel: number) => void;
  children?: React.ReactNode;
  onMapDrag?: () => void;
  featureHorizonHeight?: number;
}

interface MapContextProps {
  mapInstance: LivingMap | null;
  controlTheme: "light" | "dark";
  controlSize: "small" | "medium" | "large";
  floorControlInstance: FloorControl | null;
  featureHorizonHeight?: number;
}

export const MapContext = createContext<MapContextProps>({
  mapInstance: null,
  controlTheme: "light",
  controlSize: "small",
  floorControlInstance: null,
});

const Map: React.FC<Props> = ({
  mapID,
  bearing,
  center,
  extent,
  maxZoom,
  minZoom,
  zoom,
  mapStyle,
  accessToken,
  floor,
  floors,
  youMarker,
  onFeatureSelect,
  onMapReady,
  controlTheme = "light",
  controlSize = "small",
  interactive = true,
  enableTouchZoom,
  children,
  onTouch,
  onZoom,
  onMapDrag,
  featureHorizonHeight,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [mapInstance, setMapInstance] = useState<LivingMap | null>(null);

  const mapContainer = useRef<HTMLDivElement | null>(null);
  const floorControlInstance = useRef<FloorControl | null>(null);
  const interactionControlInstance = useRef<InteractionPlugin | null>(null);
  const isTouchScreen = useRef<boolean>(false); // Used to make sure only one of the 3 map interaction event handlers fires. Using a ref instead of state, so it doesn't cause the component to unnecessarily re-render and so the other event handlers can use the updated value without the delay you'd get from updating state. Ultimately this is just here to ensure that we don't register multiple touch events for a single click/touch/drag from a user

  useEffect(() => {
    setIsLoading(true);

    const mapConfig: LivingMapOptions = {
      accessToken,
      zoom,
      maxZoom,
      minZoom,
      center,
      extent,
      bearing,
      style: mapStyle,
      interactive,
      hash: false,
      enableTouchPitch: false,
      enableTouchZoomRotate: false,
    };

    const map = new LivingMap(mapContainer.current!, mapConfig);

    const positionPlugin = new PositionControl(PLUGIN_IDS.USER_LOCATION, map);
    floorControlInstance.current = new FloorControl(PLUGIN_IDS.FLOOR, map);
    const clusteredPinPlugin = new ClusteredPinPlugin(
      PLUGIN_IDS.CLUSTERED_PIN,
      map,
      floorControlInstance.current,
      floors,
      onTouch,
      onFeatureSelect,
    );

    interactionControlInstance.current = new InteractionPlugin(
      PLUGIN_IDS.INTERACTION,
      map,
      clusteredPinPlugin,
    );

    const floorToRender: FloorConfig = floors[floor];

    map.addPlugin(positionPlugin);
    map.addPlugin(floorControlInstance.current);
    map.addPlugin(clusteredPinPlugin);
    map.addPlugin(interactionControlInstance.current);

    map.create();

    map.on("style.load", () => {
      positionPlugin.activate();
      positionPlugin.setMarker({ ...youMarker, floorID: floorToRender.id });
      floorControlInstance.current?.activate();
      floorControlInstance.current?.setGroundFloor(floors);
      floorControlInstance.current?.setActiveFloor(floorToRender);

      interactionControlInstance.current?.activate();
    });

    map.on("render", function () {
      map?.getMapboxMap().resize();
    });

    map.on("load", () => {
      const mapboxMap = map.getMapboxMap();

      if (enableTouchZoom) {
        mapboxMap.touchZoomRotate.enable();
        mapboxMap.touchZoomRotate.disableRotation();
      } else {
        mapboxMap.touchZoomRotate.disable();
      }

      mapboxMap.dragRotate.disable();
      mapboxMap.scrollZoom.disable();
      mapboxMap.doubleClickZoom.disable();

      // Add the instance of LivingMap to the window object to assist with testing
      if (process.env.REACT_APP_STAGING === "true") {
        window.livingMap = map;
        console.log(
          "LivingMap instance injected into window.livingMap successfully",
        );
      }

      setIsLoading(false);
      onMapReady && onMapReady(map);
    });

    if (interactive) {
      const mapboxMap = map.getMapboxMap();

      mapboxMap.on("touchstart", () => {
        // Set this to true if "touchstart" is triggered, so that the "click" and "dragstart" events don't fire another "onTouch" call
        isTouchScreen.current = true;

        onTouch && onTouch(InteractionEventTypes.MAP_TOUCH);
      });

      // The "onTouch" calls below will only get executed if "touchstart" wasn't triggered above
      mapboxMap.on("click", () => {
        if (!isTouchScreen.current) {
          onTouch && onTouch(InteractionEventTypes.MAP_TOUCH);
        }

        isTouchScreen.current = false;
      });

      mapboxMap.on("dragstart", () => {
        onMapDrag && onMapDrag();
        if (!isTouchScreen.current) {
          onTouch && onTouch(InteractionEventTypes.MAP_TOUCH);
        }

        isTouchScreen.current = false;
      });

      mapboxMap.on("zoomend", () => {
        onZoom && onZoom(mapboxMap.getZoom());
      });
    }

    setMapInstance(map);
    // eslint-disable-next-line
  }, [mapID]); // this is the only required dependency

  return (
    <div className={styles.container}>
      {isLoading && (
        <div className={styles.loaderContainer}>
          <Spinner type="BeatLoader" />
        </div>
      )}
      <div ref={mapContainer} className={styles.map} />
      <MapContext.Provider
        value={{
          mapInstance,
          controlTheme,
          controlSize,
          floorControlInstance: floorControlInstance.current,
          featureHorizonHeight,
        }}
      >
        {children}
      </MapContext.Provider>
    </div>
  );
};

export default Map;
