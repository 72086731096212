import { useContext, Dispatch, SetStateAction } from "react";
import classNames from "classnames";

import { InteractionEventTypes } from "../../../../redux/services/config";

import { MapContext } from "../../Map";

import { ReactComponent as Chevron } from "./assets/chevron_up.svg";

import styles from "./PanControl.module.scss";

const panDistance = 200;

export interface PanControlProps {
  hasPanned: boolean;
  setHasPanned: Dispatch<SetStateAction<boolean>>;
  onTouch?: (eventType: InteractionEventTypes) => void;
}

const PanControl: React.FC<PanControlProps> = ({
  onTouch,
  hasPanned,
  setHasPanned,
}) => {
  const { mapInstance, controlSize, controlTheme } = useContext(MapContext);

  const mapboxMap = mapInstance?.getMapboxMap();

  mapboxMap?.on("dragstart", () => {
    if (!hasPanned && setHasPanned) setHasPanned(true);
  });

  const handlePanClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    direction: "up" | "right" | "down" | "left",
  ) => {
    event.stopPropagation();

    switch (direction) {
      case "up":
        mapInstance?.getMapboxMap().panBy([0, -panDistance]);
        break;
      case "down":
        mapInstance?.getMapboxMap().panBy([0, panDistance]);
        break;
      case "right":
        mapInstance?.getMapboxMap().panBy([panDistance, 0]);
        break;
      case "left":
        mapInstance?.getMapboxMap().panBy([-panDistance, 0]);
        break;
    }

    onTouch && onTouch(InteractionEventTypes.PAN_CONTROL_TOUCH);

    if (!hasPanned && setHasPanned) setHasPanned(true);
  };

  return (
    <div
      className={classNames(
        styles.container,
        styles[controlSize],
        styles[controlTheme],
      )}
    >
      <button
        data-qa="direction-up"
        className={styles.buttonUp}
        onClick={(event) => handlePanClick(event, "up")}
      >
        <Chevron className={styles.chevron} />
      </button>
      <button
        data-qa="direction-right"
        className={styles.buttonRight}
        onClick={(event) => handlePanClick(event, "right")}
      >
        <Chevron className={styles.chevron} />
      </button>
      <button
        data-qa="direction-down"
        className={styles.buttonDown}
        onClick={(event) => handlePanClick(event, "down")}
      >
        <Chevron className={styles.chevron} />
      </button>
      <button
        data-qa="direction-left"
        className={styles.buttonLeft}
        onClick={(event) => handlePanClick(event, "left")}
      >
        <Chevron className={styles.chevron} />
      </button>
    </div>
  );
};

export default PanControl;
