import { useContext } from "react";
import classNames from "classnames";
import { Icon } from "@livingmap/core-ui-v2";

import { MapContext } from "../../Map";

import styles from "./SearchControl.module.scss";

export interface SearchControlProps {
  dataQA: string;
  buttonStyle: "smallSquare" | "largeCircle";
  className?: string;
  onClick: () => void;
  project: string;
}

const SearchControl: React.FC<SearchControlProps> = ({
  dataQA,
  onClick,
  className,
  project,
}) => {
  const { controlSize, controlTheme } = useContext(MapContext);

  const projectTitle = project
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
    .join(" ");

  return (
    <button
      data-qa={dataQA}
      className={classNames(
        className,
        styles.button,
        styles[controlTheme],
        styles[controlSize],
      )}
      onClick={onClick}
    >
      <Icon
        dataQA={`${dataQA}-icon`}
        type="SearchIcon"
        className={styles.svg}
      />
      <span className={styles.text}>{`Search ${projectTitle}`}</span>
    </button>
  );
};

export default SearchControl;
