import {
  addDays,
  differenceInHours,
  format,
  isBefore,
  isWithinInterval,
} from "date-fns";
import { useCallback, useMemo } from "react";
import { OpeningTimes, Day } from "../redux/services/config";
import { convertTimeToDate } from "../utils/convertTimeToDate";

export enum Status {
  OPEN = "Open",
  CLOSED = "Closed",
  OPEN_24_HOURS = "Open 24 hours",
  TEMP_CLOSED = "Temporarily closed", // need something from backend
}

export const useCalculateOperatingHours = (operatingHours: OpeningTimes) => {
  const currentDate = useMemo(() => new Date(), []);

  const todaysDay = format(currentDate, "eeee").toLowerCase() as Day;
  const todaysOpeningHours = operatingHours[todaysDay];

  const status = useMemo(() => {
    if (!todaysOpeningHours) return Status.CLOSED;

    const openingTime = convertTimeToDate(todaysOpeningHours.opening_time);
    const closingTime = convertTimeToDate(todaysOpeningHours.closing_time);

    if (differenceInHours(closingTime, openingTime) === 24)
      return Status.OPEN_24_HOURS;

    // if the closing_time is bigger than the opening_time we need to check 2 intervals
    if (closingTime < openingTime) {
      const isWithinClosingInterval = isWithinInterval(currentDate, {
        start: convertTimeToDate("00:00"),
        end: closingTime,
      });
      const isWithinOpeningInterval = isWithinInterval(currentDate, {
        start: openingTime,
        end: convertTimeToDate("24:00"),
      });

      if (isWithinClosingInterval || isWithinOpeningInterval) {
        return Status.OPEN;
      }

      return Status.CLOSED;
    } else {
      if (
        isWithinInterval(currentDate, {
          start: openingTime,
          end: closingTime,
        })
      ) {
        return Status.OPEN;
      }

      return Status.CLOSED;
    }
  }, [todaysOpeningHours, currentDate]);

  const formatTime = useCallback((time: string) => {
    const formattedTime = convertTimeToDate(time);
    const timeFormat = formattedTime.getMinutes() !== 0 ? "h.mm aaa" : "h aaa";

    return format(formattedTime, timeFormat);
  }, []);

  const checkNextOpeningTime = useCallback(
    (openToday = false) => {
      if (openToday && todaysOpeningHours) {
        if (
          isBefore(
            currentDate,
            convertTimeToDate(todaysOpeningHours.opening_time),
          )
        ) {
          return `Closed until ${formatTime(todaysOpeningHours.opening_time)}`;
        }
      }

      // check the closest opening_time excluding today
      for (let i = 1; i <= 6; i++) {
        const nextDay = addDays(currentDate, i);
        const day = format(nextDay, "eeee").toLowerCase() as Day;

        if (operatingHours[day]) {
          const time = formatTime(operatingHours[day]?.opening_time as string);
          const dayText = i > 1 ? format(nextDay, "EEE") : "";
          return `Closed until ${time} ${dayText}`;
        }
      }
    },
    [currentDate, formatTime, operatingHours, todaysOpeningHours],
  );

  const statusCopy = useMemo(() => {
    if (!todaysOpeningHours) return checkNextOpeningTime();

    switch (status) {
      case Status.OPEN_24_HOURS:
        return Status.OPEN_24_HOURS;
      case Status.OPEN:
        return `Open until ${formatTime(todaysOpeningHours.closing_time)}`;
      case Status.CLOSED:
        return checkNextOpeningTime(true);
      default:
        return;
    }
  }, [checkNextOpeningTime, status, formatTime, todaysOpeningHours]);

  return { status, formatTime, statusCopy, todaysDay };
};
