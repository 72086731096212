import { createRoot } from "react-dom/client";
import mapboxgl from "mapbox-gl";
import LivingMap, {
  LayerDelegate,
  LivingMapPlugin,
  LMFeature,
  StateType,
} from "@livingmap/core-mapping";
import { Feature, GeoJsonProperties, Geometry } from "geojson";

import FloorControl from "./floor-control";
import { LayerIds, SourceIds } from "./types/index";
import { EMPTY_DATA_SOURCE } from "./position-control";
import {
  getFloorById,
  getControlTheme,
  ControlTheme,
  parseOpeningTimeString,
} from "../../../utils";
import { Floors, InteractionEventTypes } from "../../../redux/services/config";
import AssetInformation from "../components/AssetInformation/AssetInformation";
import AssetLabel from "../components/AssetLabel/AssetLabel";
import { OnTouchHandlerOptions } from "../../../templates/BaseWithHeader/BaseWithHeader";

enum FeatureDotTypes {
  ACTIVE = "active-feature-dot",
  INACTIVE = "inactive-feature-dot",
}

interface PopupStore {
  [key: string]: mapboxgl.Popup;
}

interface ActiveMapboxFeatureStore {
  [key: string]: Feature<Geometry, GeoJsonProperties>;
}

class ClusteredPinPlugin extends LivingMapPlugin {
  private features: LMFeature[] = [];
  private floorPlugin: FloorControl;
  private mapInstance?: mapboxgl.Map;
  private layerDelegate: LayerDelegate;
  private popupStore: PopupStore = {};
  private activeMapboxFeatureStore: ActiveMapboxFeatureStore = {};
  private controlTheme: ControlTheme | null;
  private floors: Floors;
  private onTouch:
    | ((
        eventType: InteractionEventTypes,
        options?: OnTouchHandlerOptions,
      ) => void)
    | undefined;
  private onFeatureSelect: ((feature: Feature | null) => void) | undefined;

  public constructor(
    id: string,
    LMMap: LivingMap,
    floorPlugin: FloorControl,
    floors: Floors,
    onTouch?: (eventType: InteractionEventTypes) => void,
    onFeatureSelect?: (feature: Feature | null) => void,
  ) {
    super(id, LMMap);
    this.LMMap = LMMap;
    this.floorPlugin = floorPlugin;
    this.layerDelegate = LMMap.getLayerDelegate();
    this.controlTheme = getControlTheme();
    this.floors = floors;
    this.onTouch = onTouch;
    this.onFeatureSelect = onFeatureSelect;
  }

  activate(): void {
    this.mapInstance = this.LMMap.getMapboxMap();
    return;
  }

  public updateFeatureLabels(
    features: LMFeature[],
    updateActiveFloor?: boolean,
  ): void {
    this.features = features;
    this.clearFeatureLabels(false);

    if (
      !this.mapInstance?.hasImage(FeatureDotTypes.ACTIVE) &&
      !this.mapInstance?.hasImage(FeatureDotTypes.INACTIVE)
    ) {
      this.mapInstance?.addImage(
        FeatureDotTypes.ACTIVE,
        this.createFeatureDot(FeatureDotTypes.ACTIVE),
      );
      this.mapInstance?.addImage(
        FeatureDotTypes.INACTIVE,
        this.createFeatureDot(FeatureDotTypes.INACTIVE),
      );
    }

    this.layerDelegate.addSource(SourceIds.ACTIVE_FLOOR_FEATURE_SOURCE_ID, {
      type: "geojson",
      data: EMPTY_DATA_SOURCE,
    });

    this.layerDelegate.addSource(SourceIds.INACTIVE_FLOOR_FEATURE_SOURCE_ID, {
      type: "geojson",
      data: EMPTY_DATA_SOURCE,
    });

    this.layerDelegate.addLayer({
      id: LayerIds.ACTIVE_FLOOR_FEATURE_LAYER,
      type: "symbol",
      source: SourceIds.ACTIVE_FLOOR_FEATURE_SOURCE_ID,
      layout: {
        "icon-image": FeatureDotTypes.ACTIVE,
      },
    });

    this.layerDelegate.addLayer({
      id: LayerIds.INACTIVE_FLOOR_FEATURE_LAYER,
      type: "symbol",
      source: SourceIds.INACTIVE_FLOOR_FEATURE_SOURCE_ID,
      layout: {
        "icon-image": FeatureDotTypes.INACTIVE,
      },
    });

    // Update the active floor if there's only a single feature present
    if (this.features.length === 1 && updateActiveFloor) {
      const featureFloorId = this.features[0]?.getFloorId();
      const featureFloor = featureFloorId && getFloorById(featureFloorId);
      if (featureFloor) this.floorPlugin.setActiveFloor(featureFloor);
    }

    this.renderActiveAndInactiveFeatures();
  }

  public reloadFeatureLabels(): void {
    this.updateFeatureLabels(this.features);
  }

  public clearFeatureLabels(deleteFeatures: boolean = true): void {
    this.layerDelegate.removeLayer(LayerIds.ACTIVE_FLOOR_FEATURE_LAYER);
    this.layerDelegate.removeLayer(LayerIds.INACTIVE_FLOOR_FEATURE_LAYER);
    this.layerDelegate.removeSource(SourceIds.ACTIVE_FLOOR_FEATURE_SOURCE_ID);
    this.layerDelegate.removeSource(SourceIds.INACTIVE_FLOOR_FEATURE_SOURCE_ID);

    for (const key of Object.keys(this.popupStore)) {
      this.popupStore[key].remove();
    }

    this.popupStore = {};

    if (deleteFeatures) {
      this.features = [];
    }
  }

  private clearActiveMapboxFeatureStore(): void {
    this.activeMapboxFeatureStore = {};
  }

  private renderActiveAndInactiveFeatures() {
    const currentFloor = this.floorPlugin?.getActiveFloor();
    const hasFloorData = Boolean(currentFloor);

    const featuresOnActiveFloor: LMFeature[] = [];
    const featuresOnInactiveFloors: LMFeature[] = [];

    this.clearActiveMapboxFeatureStore();

    for (const feature of this.features) {
      if (!hasFloorData) {
        featuresOnActiveFloor.push(feature);
        continue;
      }

      const featureFloorId = feature.getFloorId();

      if (featureFloorId === currentFloor?.id) {
        featuresOnActiveFloor.push(feature);
      } else {
        featuresOnInactiveFloors.push(feature);
      }
    }

    const activeMapboxFeatures: Feature[] = this.enhanceLMFeatures(
      featuresOnActiveFloor,
    );
    const inactiveMapboxFeatures: Feature[] = this.enhanceLMFeatures(
      featuresOnInactiveFloors,
    );

    for (const mapboxFeature of activeMapboxFeatures.concat(
      inactiveMapboxFeatures,
    )) {
      if (!("coordinates" in mapboxFeature.geometry)) continue;

      const lmID = mapboxFeature?.properties?.lm_id;

      if (!lmID) return;

      if (this.features.length === 1) {
        this.renderExpandedAssetInformation(lmID, mapboxFeature, true);
      } else {
        this.activeMapboxFeatureStore[lmID] = mapboxFeature;
        this.renderAssetLabel(lmID, mapboxFeature);
      }
    }

    const activeFloorSourceProxy = this.layerDelegate.getSourceProxy(
      SourceIds.ACTIVE_FLOOR_FEATURE_SOURCE_ID,
    );
    const inactiveFloorSourceProxy = this.layerDelegate.getSourceProxy(
      SourceIds.INACTIVE_FLOOR_FEATURE_SOURCE_ID,
    );

    activeFloorSourceProxy?.setData({
      type: "FeatureCollection",
      features: activeMapboxFeatures,
    });

    inactiveFloorSourceProxy?.setData({
      type: "FeatureCollection",
      features: inactiveMapboxFeatures,
    });
  }

  private renderExpandedAssetInformation(
    id: string,
    feature: Feature,
    deleteOnClose: boolean,
  ) {
    if (!("coordinates" in feature.geometry)) return;

    this.onFeatureSelect && this.onFeatureSelect(feature);

    this.onTouch &&
      this.onTouch(InteractionEventTypes.ASSET_DIALOG_OPEN, {
        featureID: feature.id,
      });

    const screenId = new URLSearchParams(window.location.search).get(
      "screen_id",
    )!;
    const container = document.createElement("div");
    const root = createRoot(container);

    const handleOnClick = () => {
      deleteOnClose
        ? this.deleteFeature(id)
        : this.renderAssetLabel(id, feature);
      this.onFeatureSelect && this.onFeatureSelect(null);
    };

    const singleFeature = this.features.length === 1;

    /**
     * The backend API converts the operating hours string for a feature into an object, but if we click on a feature on the map that's not a search result we have the raw operating hours string, so we need to do this conversion in the FE when necessary.
     * */
    const operatingHours = feature.properties?.opening_times || null;
    const formattedOperatingHours =
      typeof operatingHours === "string" || operatingHours === null
        ? parseOpeningTimeString(operatingHours)
        : operatingHours;

    root.render(
      <AssetInformation
        dataQA="asset-information"
        name={feature.properties?.name}
        category={feature.properties?.category}
        address={feature.properties?.street_address}
        phoneNumber={feature.properties?.tel_number}
        operatingHours={formattedOperatingHours}
        website={feature.properties?.url}
        theme={{
          mode: this.controlTheme?.mode,
          size: this.controlTheme?.size,
        }}
        qrCodeLink={`https://livingmap.link/r/${screenId}/${feature.id}`}
        floorName={singleFeature ? this.getFloorName(feature) : undefined}
        onClick={handleOnClick}
      />,
    );

    for (const key in this.activeMapboxFeatureStore) {
      this.renderAssetLabel(key, this.activeMapboxFeatureStore[key]);
    }

    this.popupStore[id]?.remove();

    this.popupStore[id] = new mapboxgl.Popup({
      closeOnClick: false,
      closeButton: false,
      offset: {
        top: [0, 10],
        "top-left": [10, 10],
        "top-right": [-10, 10],
        bottom: [0, -10],
        "bottom-left": [10, -10],
        "bottom-right": [-10, -10],
        right: [-10, 0],
        left: [10, 0],
      },
      className: `${this.controlTheme?.mode} expanded`,
    })
      .setLngLat(feature.geometry.coordinates as [number, number])
      .setDOMContent(container)
      .addTo(this.mapInstance!);
  }

  private renderAssetLabel(id: string, feature: Feature) {
    if (!("coordinates" in feature.geometry)) return;

    this.onTouch && this.onTouch(InteractionEventTypes.ASSET_DIALOG_CLOSE);

    const floorName = this.getFloorName(feature);

    const container = document.createElement("div");
    const root = createRoot(container);

    root.render(
      <AssetLabel
        dataQA="asset-label"
        theme={{
          mode: this.controlTheme?.mode,
          size: this.controlTheme?.size,
        }}
        name={feature.properties?.name}
        floor={floorName}
        onClick={() => this.renderExpandedAssetInformation(id, feature, false)}
      />,
    );

    this.popupStore[id]?.remove();

    this.popupStore[id] = new mapboxgl.Popup({
      closeOnClick: false,
      closeButton: false,
      offset: {
        top: [0, 10],
        "top-left": [10, 10],
        "top-right": [-10, 10],
        bottom: [0, -10],
        "bottom-left": [10, -10],
        "bottom-right": [-10, -10],
        right: [-10, 0],
        left: [10, 0],
      },
      className: this.controlTheme?.mode,
    })
      .setLngLat(feature.geometry.coordinates as [number, number])
      .setDOMContent(container)
      .addTo(this.mapInstance!);
  }

  private getFloorName(feature: Feature) {
    const showFloorDetail = Object.keys(this.floors).length > 1;

    let floorName: string | undefined;

    if (showFloorDetail) {
      floorName = feature.properties?.floor_name;
    }

    return floorName;
  }

  private deleteFeature(id: string) {
    if (this.features.length < 1) return;

    const featureIndex = this.features.findIndex(
      (feature) => feature.getLmId() === id,
    );
    if (featureIndex === -1) return;

    const popup = this.popupStore[id];

    if (popup) {
      popup.remove();
      delete this.popupStore[id];
    }

    this.features.splice(featureIndex, 1);
    this.reloadFeatureLabels();
  }

  private enhanceLMFeatures(LMFeatures: LMFeature[]) {
    const currentlySelectedFeature =
      this.LMMap.getFeatureStateDelegate().getFeatureForState(
        StateType.SELECTED,
      );

    return LMFeatures.map((lmFeature: LMFeature) => {
      // Throws an error about the object not being extendable, so only seems to work if it's deep-cloned
      const feature = JSON.parse(JSON.stringify(lmFeature.getMapboxFeature()));

      const featureFloorId = lmFeature.getFloorId();
      const featureFloor = featureFloorId && getFloorById(featureFloorId);

      if (featureFloor) {
        feature.properties.floor_name = `Floor ${featureFloor.short_name}`;
        feature.properties.floor_id = undefined; // this is needed so that the feature dots appear on all floors
        feature.properties.poi_floor_id = featureFloor.id;
      }

      if (
        currentlySelectedFeature !== null &&
        currentlySelectedFeature.getId() === lmFeature.getId()
      ) {
        feature.properties.selected = "active";
      } else {
        feature.properties.selected = "inactive";
      }

      const centroid = lmFeature.getCentroid();
      if (!centroid)
        throw new Error(
          `Centroid does not exist on LMFeature for: ${lmFeature.getId()}`,
        );

      feature.geometry = {
        type: "Point",
        coordinates: centroid,
      };

      feature.properties = {
        ...feature.properties,
        preventClickPropagation: true,
      };

      // Mapbox doesn't handle null values correctly, so remove any property that is null to allow
      // pins to be displayed correctly
      for (const k in feature.properties) {
        if (feature.properties[k] === null) {
          delete feature.properties[k];
        }
      }

      return feature;
    });
  }

  private createFeatureDot(type: FeatureDotTypes) {
    let size = 0;
    let innerColour = "";
    let data: Uint8Array | Uint8ClampedArray = new Uint8Array(size * size * 4);
    const borderColour = "#fff";

    if (type === FeatureDotTypes.ACTIVE) {
      size = 40;
      innerColour = "#ff7100";
    } else {
      size = 35;
      innerColour = "#666666";
    }

    const canvas = document.createElement("canvas");
    canvas.width = size;
    canvas.height = size;
    const context = canvas.getContext("2d");

    const radius = (size / 2) * 0.3;

    context!.clearRect(0, 0, size, size);

    // Draw the inner circle.
    context!.beginPath();
    context!.arc(size / 2, size / 2, radius, 0, Math.PI * 2);
    context!.fillStyle = innerColour;
    context!.strokeStyle = borderColour;
    context!.lineWidth = 2;
    context!.shadowOffsetX = 0;
    context!.shadowOffsetY = 0;
    context!.shadowBlur = 8;
    context!.shadowColor = "rgba(0, 0, 0, 0.3)";
    context!.fill();
    context!.stroke();

    // Update this image's data with data from the canvas.
    data = context!.getImageData(0, 0, size, size).data;

    return {
      width: size,
      height: size,
      data,
    };
  }
}

export default ClusteredPinPlugin;
