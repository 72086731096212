import { Icon, IconType } from "@livingmap/core-ui-v2";
import classNames from "classnames";

import { UISize, UITheme } from "../types";

import styles from "./LocationButton.module.scss";

export enum LocationStatus {
  INACTIVE = "inactive",
  SEARCHING = "searching",
  FOUND = "found",
  DISABLED = "disabled",
}

interface Props {
  className?: string;
  status: LocationStatus;
  theme?: UITheme;
  size?: UISize;
  onClick: () => void;
  dataQA: string;
}

const LocationButton: React.FC<Props> = ({
  className,
  status,
  theme = "light",
  size = "small",
  onClick,
  dataQA,
}) => {
  let iconType: IconType = "NearMeOutlineIcon";

  switch (status) {
    case LocationStatus.FOUND:
      iconType = "NearMeIcon";
      break;
    case LocationStatus.DISABLED:
      iconType = "NearMeDisabledOutlineIcon";
      break;
    default:
      iconType = "NearMeOutlineIcon";
  }

  return (
    <button
      data-qa={dataQA}
      className={classNames(
        styles.container,
        styles[theme],
        styles[size],
        className,
        {
          [styles.found]: status === LocationStatus.FOUND,
          [styles.searching]: status === LocationStatus.SEARCHING,
        },
      )}
      onClick={onClick}
    >
      <Icon dataQA="icon" type={iconType} />
    </button>
  );
};

export default LocationButton;
