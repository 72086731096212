import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import moment from "moment-timezone";
import "moment/min/locales";

import { Theme, Lang } from "../../redux/services/config";
import { useLanguageSwitcher } from "../../hooks";
import styles from "./Header.module.css";

interface Props {
  time: string;
  timezone: string;
  languages: string[];
  theme: Theme;
  lang: Lang;
  languageInterval?: number;
  icon?: {
    svg_base64: string;
  };
}

const Header: React.FC<Props> = ({
  time,
  timezone,
  languages,
  theme,
  lang,
  icon,
  languageInterval = 8000,
}) => {
  const clientTime = useRef(new Date().getTime());
  const [serverTime, setServerTime] = useState(new Date(time).getTime());

  // Increment time using the server's time as a baseline starting point
  useEffect(() => {
    setTimeout(() => {
      const currentMilliseconds = new Date().getTime();
      const difference = currentMilliseconds - clientTime.current;
      setServerTime(serverTime + difference);
      clientTime.current = currentMilliseconds;
    }, 1000);
  }, [serverTime]);

  const currentLanguage = useLanguageSwitcher(languages, languageInterval);

  return (
    <div
      className={styles.container}
      style={{
        backgroundColor: theme.background_color,
        fontFamily: theme.font_family,
        color: theme.text_color,
      }}
    >
      {languages.map((language, index) => {
        if (index !== currentLanguage) return null;

        const timeFormat = lang[language].time_format;

        return (
          <React.Fragment key={language}>
            <div
              className={classNames({
                [styles.withIcon]: icon?.svg_base64,
              })}
            >
              {icon && (
                <img
                  data-qa="title-image"
                  src={`data:image/svg+xml;base64,${icon.svg_base64}`}
                  alt="Header icon"
                  className={styles.icon}
                />
              )}
              <div>
                {lang[language].heading && (
                  <h1 data-qa="title" className={styles.heading}>
                    {lang[language].heading}
                  </h1>
                )}
                {lang[language].subheading && (
                  <h2 data-qa="sub-title" className={styles.subheading}>
                    {lang[language].subheading}
                  </h2>
                )}
              </div>
            </div>
            {timeFormat && (
              <p data-qa="time" className={styles.time}>
                {moment(serverTime)
                  .tz(timezone)
                  .locale(language)
                  .format(timeFormat)}
              </p>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default Header;
