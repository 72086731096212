import { useState, useEffect } from "react";
import GitInfo from "react-git-info/macro";

import { TEMPLATES } from "./templates";
import BaseWithHeader from "./templates/BaseWithHeader/BaseWithHeader";
import {
  useGetConfigQuery,
  useGetFeatureListQuery,
  useHeartbeatPingMutation,
} from "./redux/services/config";
import { errorHandler } from "./utils";
import packageJSON from "../package.json";
import "./App.css";

export enum LAYOUTS {
  PORTRAIT = "portrait",
  LANDSCAPE = "landscape",
}

function App() {
  const screenId = new URLSearchParams(window.location.search).get("screen_id");

  if (!screenId) {
    window.displayErrorScreen("screen_id must be supplied to load app");
    throw new Error("screen_id must be supplied to load app");
  }

  const [uptime, setUptime] = useState<string | null>(null);

  const {
    data,
    isError: isConfigError,
    isLoading,
    error: configError,
  } = useGetConfigQuery(screenId);

  const { data: featuresResponse } = useGetFeatureListQuery(screenId);

  const [heartbeatPing, { isSuccess: heartbeatSuccess }] =
    useHeartbeatPingMutation();

  const { commit, branch } = GitInfo();

  useEffect(() => {
    if (data) {
      console.log(
        `Version: ${packageJSON.version}\nBranch: ${branch}\nCommit hash: ${commit.hash}`
      );
      console.log(data);

      const currentTime = new Date(Date.now()).toISOString();
      setUptime(currentTime);

      heartbeatPing({
        id: screenId,
        up_since: currentTime,
        screen: { width: window.innerWidth, height: window.innerHeight },
        frontend: { version: packageJSON.version, commit: commit.hash },
      });
    }
  }, [data, heartbeatPing, branch, commit.hash, screenId]);

  useEffect(() => {
    if (heartbeatSuccess && uptime) {
      setTimeout(() => {
        heartbeatPing({
          id: screenId,
          up_since: uptime,
          screen: { width: window.innerWidth, height: window.innerHeight },
          frontend: { version: packageJSON.version, commit: commit.hash },
        });
      }, 1000 * 60);
    }
  }, [heartbeatPing, heartbeatSuccess, screenId, uptime, commit.hash]);

  if (isConfigError) {
    errorHandler(configError);
    return null;
  }

  if (isLoading) return <div>Loading...</div>;
  if (!data) return <div>Config does not exist!</div>;

  const { template } = data;

  switch (template) {
    case TEMPLATES.BASE_WITH_HEADER:
      return <BaseWithHeader data={data} features={featuresResponse?.data} />;
    default:
      return <BaseWithHeader data={data} features={featuresResponse?.data} />;
  }
}

export default App;
