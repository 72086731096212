import React, { useContext, useEffect, useState } from "react";
import classNames from "classnames";

import { InteractionEventTypes } from "../../../../redux/services/config";

import { MapContext } from "../../Map";

import Button from "../../../Button/Button";
import Modal from "../Modal/Modal";

import styles from "./CentreControl.module.scss";

export interface CentreControlProps {
  isInactive: boolean;
  inactiveText?: string;
  onRecentre: () => void;
  hasPanned: boolean;
  onTouch?: (eventType: InteractionEventTypes) => void;
  noticeTimeout: number;
  onTimeout?: () => void;
}

const CentreControl: React.FC<CentreControlProps> = ({
  isInactive,
  inactiveText = "Are you still there?",
  onRecentre,
  onTouch,
  hasPanned,
  noticeTimeout,
  onTimeout,
}) => {
  const { controlSize, controlTheme } = useContext(MapContext);
  const [countdownTimeInSeconds, setCountdownTimeInSeconds] = useState(
    noticeTimeout / 1000,
  );

  const handleExtendSession = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();

    onTouch && onTouch(InteractionEventTypes.SESSION_EXTENDER_TOUCH);
  };

  useEffect(() => {
    if (countdownTimeInSeconds === 0 || !isInactive) {
      setCountdownTimeInSeconds(noticeTimeout / 1000); // Reset if the timeout has run down to 0
      if (countdownTimeInSeconds === 1) onTimeout && onTimeout();
      return;
    }

    const intervalID = setInterval(() => {
      setCountdownTimeInSeconds((prevCountdown) => prevCountdown - 1);
    }, 1000);

    return () => {
      clearInterval(intervalID);
    };
  }, [countdownTimeInSeconds, isInactive, noticeTimeout, onTimeout]);

  return isInactive ? (
    <Modal
      dataQA="inactivity-modal"
      size={controlSize}
      theme={controlTheme}
      title={inactiveText}
    >
      <div
        className={classNames(
          styles.countDownTextContainer,
          styles[controlTheme],
          styles[controlSize],
        )}
      >
        <div className={styles.countDownText}>{countdownTimeInSeconds}</div>
      </div>
      <Button
        dataQA="continue"
        className={classNames(styles.continueButton, styles[controlSize])}
        onClick={handleExtendSession}
        rounded
        size={controlSize}
        color={controlTheme === "light" ? "black" : "white"}
        label="Continue browsing"
      />
      {hasPanned && (
        <Button
          dataQA="recentre-button"
          className={classNames(styles.recentreButton, styles[controlTheme])}
          onClick={onRecentre}
          leftIcon="NearMeIcon"
          rounded
          size={controlSize}
          color={controlTheme === "light" ? "white" : "black"}
          label="Recentre map"
        />
      )}
    </Modal>
  ) : null;
};

export default CentreControl;
