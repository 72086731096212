import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { UITheme, UISize } from "./../../components/types";

export enum InteractionEventTypes {
  MAP_TOUCH = "map_touch",
  LEVEL_SELECTOR_TOUCH = "level_selector_touch",
  RE_CENTER_MAP_TOUCH = "re_center_map_touch",
  PAN_CONTROL_TOUCH = "pan_control_touch",
  ZOOM_CONTROL_TOUCH = "zoom_control_touch",
  SESSION_EXTENDER_TOUCH = "session_extender_touch",
  SEARCH_CONTROL_TOUCH = "search_control_touch",
  SEARCH_RESULT_OPEN = "search_result_open",
  SEARCH_DIALOG_CLOSE = "search_dialog_close",
  ASSET_DIALOG_OPEN = "asset_dialog_open",
  ASSET_DIALOG_CLOSE = "asset_dialog_close",
}

export interface Theme {
  mode?: UITheme;
  size?: UISize;
  background_color?: string;
  font_family?: string;
  text_color?: string;
  heading_color?: string;
  link_color?: string;
}

export interface Lang {
  [key: string]: {
    heading?: string;
    subheading?: string;
    time_format?: string | null;
    call_to_action?: string;
    scan_qr?: string;
    use_link?: string;
    text?: string;
  };
}

interface ConfigurationComponent {
  [key: string]: {
    theme: Theme;
    lang?: Lang;
    [key: string]: any;
  };
}

export interface Floors {
  [key: string]: {
    id: number;
    floor: string;
    name: string;
    short_name: string;
  };
}

export enum Day {
  MONDAY = "monday",
  TUESDAY = "tuesday",
  WEDNESDAY = "wednesday",
  THURSDAY = "thursday",
  FRIDAY = "friday",
  SATURDAY = "saturday",
  SUNDAY = "sunday",
}

export type DayInfo = {
  opening_time: string;
  closing_time: string;
} | null;

export type OpeningTimes = {
  [key in Day]: DayInfo;
};

export interface Feature {
  type: "Feature";
  geometry: GeoJSON.Geometry;
  id: number;
  properties: {
    lm_id: string;
    floor_id: number;
    name: string;
    popup_header: string | null;
    popup_image_url: string | null;
    street_address: string | null;
    category: string;
    geom_type: string;
    opening_times: OpeningTimes;
    is_temporarily_closed: boolean;
    popup_subheader: string | null;
  };
  layer: {
    id: string;
    type: string;
  };
  source: string;
  sourceLayer: string;
}

export interface ConfigurationResponse {
  components: ConfigurationComponent;
  display: {
    resolution_x: number;
    resolution_y: number;
  };
  languages: string[];
  location: {
    bearing: number;
    floor: number;
    latitude: number;
    longitude: number;
    timezone: string;
  };
  project: string;
  screen_id: string;
  screen_name: string;
  server: {
    commit: string;
    env: string;
    time: string;
    version: string;
  };
  stylesheet: string;
  template: string;
  floors: Floors;
  map_key: string;
}

interface HeartbeatPingResponse {
  type: "created" | "rate_limited";
  code: 201 | 429 | 503;
  message: string;
}

interface HeartbeatPingRequestBody {
  id: string;
  up_since: string;
  screen: {
    width: number;
    height: number;
  };
  frontend: {
    version: string;
    commit: string;
  };
}

export interface EventType {
  timestamp: string;
  event_type: InteractionEventTypes;
}

interface SessionDataRequestBody {
  id: string;
  session_start: string;
  session_end: string;
  component: string;
  events: EventType[];
}

interface SessionDataResponse {
  type: string;
  code: number;
  message: string;
}

interface FeatureListResponse {
  object: string;
  url: string;
  data: Feature[];
}

export const configApi = createApi({
  reducerPath: "configApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
  }),
  endpoints: (builder) => ({
    getConfig: builder.query<ConfigurationResponse, string>({
      query: (id) => `/${id}`,
    }),
    getFeatureList: builder.query<FeatureListResponse, string>({
      query: (id) => `/${id}/features`,
    }),
    heartbeatPing: builder.mutation<
      HeartbeatPingResponse,
      HeartbeatPingRequestBody
    >({
      query: ({ id, up_since, screen, frontend }) => ({
        url: `/${id}/ping`,
        method: "POST",
        body: { up_since, screen, frontend },
      }),
    }),
    postSessionData: builder.mutation<
      SessionDataResponse,
      SessionDataRequestBody
    >({
      query: ({ id, ...requestBody }) => ({
        url: `/${id}/session`,
        method: "POST",
        body: requestBody,
      }),
    }),
  }),
});

export const {
  useGetConfigQuery,
  useGetFeatureListQuery,
  useHeartbeatPingMutation,
  usePostSessionDataMutation,
} = configApi;
