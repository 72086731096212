import classNames from "classnames";
import QRCode from "react-qr-code";
import { IconButton, Icon } from "@livingmap/core-ui-v2";

import { Theme } from "../../../../redux/services/config";
import { getCurrentDay, OperatingHours, scaleValue } from "../../../../utils";
import styles from "./AssetInformation.module.scss";

interface Props {
  dataQA: string;
  theme: Theme;
  name: string;
  operatingHours: OperatingHours;
  qrCodeLink: string;
  category?: string;
  address?: string;
  phoneNumber?: string;
  website?: string;
  floorName?: string;
  className?: string;
  onClick: () => void;
}

const AssetInformation: React.FC<Props> = ({
  dataQA,
  theme,
  name,
  operatingHours,
  qrCodeLink,
  category,
  address,
  phoneNumber,
  website,
  floorName,
  className,
  onClick,
}) => {
  const currentDay = getCurrentDay() as keyof OperatingHours;

  let formattedAddress: string | null = null;

  if (address) {
    formattedAddress = address.replace(/, /g, "\n");
  }

  return (
    <div
      data-qa={dataQA}
      className={classNames(
        styles.container,
        styles[theme.mode || "light"],
        styles[theme.size || "small"],
        className
      )}
    >
      <div className={styles.assetInformationContainer}>
        <div className={styles.heading}>
          <div>
            <p className={styles.name}>{name}</p>
            {category && !floorName && (
              <p className={styles.category}>{category.replace(/_/g, " ")}</p>
            )}
            {floorName && <p className={styles.floorName}>{floorName}</p>}
          </div>
          <IconButton
            dataQA="close-button"
            icon="CloseIcon"
            className={styles.closeIcon}
            size={theme.size || "small"}
            theme={theme.mode || "light"}
            onClick={onClick}
          />
        </div>
        <div className={styles.assetInformation}>
          {formattedAddress && (
            <div data-qa="asset-address" className={styles.informationRow}>
              <Icon dataQA="address-icon" type="DirectionsIcon" />
              <p className={styles.lineBreak}>{formattedAddress}</p>
            </div>
          )}
          {phoneNumber && (
            <div data-qa="asset-phone-number" className={styles.informationRow}>
              <Icon dataQA="phone-number-icon" type="PhoneIcon" />
              <p>{phoneNumber}</p>
            </div>
          )}
          {website && (
            <div data-qa="asset-website" className={styles.informationRow}>
              <Icon dataQA="website-icon" type="WebsiteIcon" />
              <p>{website}</p>
            </div>
          )}
          {operatingHours[currentDay]?.opening_time &&
            operatingHours[currentDay]?.closing_time && (
              <div
                data-qa="asset-operating-hours"
                className={styles.informationRow}
              >
                <Icon dataQA="hours-icon" type="HoursIcon" />
                <p>{`${operatingHours[currentDay]?.opening_time} - ${operatingHours[currentDay]?.closing_time}`}</p>
              </div>
            )}
        </div>
      </div>
      <div className={styles.scanContainer}>
        <h3 className={styles.ctaHeading}>Send route to your phone</h3>
        <div className={styles.qrCodeContainer}>
          <span>Scan this code</span>
          <QRCode
            value={qrCodeLink}
            size={scaleValue(80, theme.size || "small")}
            className={styles.qrCode}
          />
        </div>
      </div>
    </div>
  );
};

export default AssetInformation;
